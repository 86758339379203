<template>
  <label v-if="label" class="control-label">{{ label }} </label>
  <select
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update: modelValue', $event.target.value);
      },
    }"
    class="form-control form-control-lg"
    :class="classes"
    :value="modelValue"
  >
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :selected="option.value === modelValue"
    >
      {{ option.text }}
    </option>
  </select>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    classes: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>